<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-card class="mt-5">
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2 mr-3"
        >
          mdi-format-list-bulleted-square
        </v-icon>
        {{$vuetify.lang.t('$vuetify.user.list')}}
        <v-spacer></v-spacer>

        <v-btn
          :to="{ name: 'systemUserCreate'}"
          class="mx-2"
          color="primary"
          fab
          dark
          x-small
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users"
          item-key="name"
          hide-default-footer
        >
          <template v-slot:item.dep="{ item }">
            {{item.department.showName}}
          </template>
          <template v-slot:item.isMonitorScreenUser="{ item }">
            {{$vuetify.lang.t(`$vuetify.common.${item.isMonitorScreenUser}`)}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-spacer/>
            <template
              v-if="item.id !== 1"
            >
              <v-icon
                class="mr-2"
                color="error"
                small
                @click="handleDeleteItem(item)"
              >
                mdi-delete
              </v-icon>
              <v-icon
                v-if="!item.isMonitorScreenUser"
                color="warning"
                class="mr-2"
                small
                @click="handleMonitorScreenItem(item)"
              >
                mdi-television
              </v-icon>
              <v-icon
                v-else
                color="warning"
                class="mr-2"
                small
                @click="handleMonitorScreenItem(item)"
              >
                mdi-television-off
              </v-icon>
              <v-icon
                v-if="!item.isEpidemicMonitorUser"
                color="purple"
                class="mr-2"
                small
                @click="handleEpidemicMonitorItem(item)"
              >
                mdi-thermometer
              </v-icon>
              <v-icon
                v-else
                color="purple"
                class="mr-2"
                small
                @click="handleEpidemicMonitorItem(item)"
              >
                mdi-thermometer-off
              </v-icon>
            </template>
            <v-icon
              color="info"
              small
              @click="handleEditItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <v-pagination
          v-model="queryParams.params.page"
          :length="queryParams.params.pageCount"
        ></v-pagination>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{$vuetify.lang.t('$vuetify.common.warning')}}
        </v-card-title>
        <v-card-text v-if="editItem">
          {{$vuetify.lang.t('$vuetify.message.deleteConfirm')}}
          <Strong>
            {{ editItem.realName }}
          </Strong>
          ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            {{$vuetify.lang.t('$vuetify.common.cancel')}}
          </v-btn>
          <v-btn
            color="error darken-1"
            @click="handleDeleteDialog"
          >
            {{$vuetify.lang.t('$vuetify.common.delete')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="monitorScreenDialog"
      persistent
      max-width="290"
    >
      <v-card
        v-if="monitorScreenDialog"
      >
        <v-card-title class="headline">
          {{$vuetify.lang.t('$vuetify.common.warning')}}
        </v-card-title>
        <template
          v-if="editItem && editItem.isMonitorScreenUser"
        >
          <v-card-text
          >
           {{$vuetify.lang.t('$vuetify.message.delMonitorScreenUserConfirm', editItem.realName)}} ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info darken-1"
              @click="monitorScreenDialog = false"
            >
              {{$vuetify.lang.t('$vuetify.common.cancel')}}
            </v-btn>
            <v-btn
              color="error darken-1"
              @click="handleDeleteMonitorScreenDialog"
            >
              {{$vuetify.lang.t('$vuetify.common.ok')}}
            </v-btn>
          </v-card-actions>
        </template>
        <template
          v-else
        >
          <v-card-text
          >
            {{$vuetify.lang.t('$vuetify.message.addMonitorScreenUserConfirm', editItem.realName)}} ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info darken-1"
              @click="monitorScreenDialog = false"
            >
              {{$vuetify.lang.t('$vuetify.common.cancel')}}
            </v-btn>
            <v-btn
              color="primary darken-1"
              @click="handleAddMonitorScreenDialog"
            >
              {{$vuetify.lang.t('$vuetify.common.ok')}}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="epidemicMonitorDialog"
      persistent
      max-width="290"
    >
      <v-card
        v-if="epidemicMonitorDialog"
      >
        <v-card-title class="headline">
          {{$vuetify.lang.t('$vuetify.common.warning')}}
        </v-card-title>
        <template
          v-if="editItem && editItem.isEpidemicMonitorUser"
        >
          <v-card-text
          >
           {{$vuetify.lang.t('$vuetify.message.delEpidemicMonitorUserConfirm', editItem.realName)}} ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info darken-1"
              @click="epidemicMonitorDialog = false"
            >
              {{$vuetify.lang.t('$vuetify.common.cancel')}}
            </v-btn>
            <v-btn
              color="error darken-1"
              @click="handleDeleteEpidemicMonitorDialog"
            >
              {{$vuetify.lang.t('$vuetify.common.ok')}}
            </v-btn>
          </v-card-actions>
        </template>
        <template
          v-else
        >
          <v-card-text
          >
            {{$vuetify.lang.t('$vuetify.message.addEpidemicMonitorUserConfirm', editItem.realName)}} ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info darken-1"
              @click="epidemicMonitorDialog = false"
            >
              {{$vuetify.lang.t('$vuetify.common.cancel')}}
            </v-btn>
            <v-btn
              color="primary darken-1"
              @click="handleAddEpidemicMonitorDialog"
            >
              {{$vuetify.lang.t('$vuetify.common.ok')}}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },
    data: () => ({
      userItems: [],
      queryParams: {
        params: {
          page: 1,
          pageCount: 1,
          pagesize: 1,
        },
        data: {}
      },
      dialogBaseForm: false,
      editStatus: false,
      editItem: null,
      deleteDialog: false,
      monitorScreenDialog: false,
      epidemicMonitorDialog: false
    }),

    computed: {
      ...mapGetters({
        users: 'user/users',
        departments: 'department/departments'
      }),
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.user.realName'),
            align: 'start',
            sortable: false,
            value: 'realName',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.dep'),
            align: 'start',
            sortable: false,
            value: 'dep',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.credentialNum'),
            align: 'start',
            sortable: false,
            value: 'credentialNum',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.phone'),
            align: 'start',
            sortable: false,
            value: 'phone',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.email'),
            align: 'start',
            sortable: false,
            value: 'email',
          },{
            text: this.$vuetify.lang.t('$vuetify.user.isMonitorScreenUser'),
            align: 'start',
            sortable: false,
            value: 'isMonitorScreenUser',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.actions'),
            align: 'end',
            sortable: false,
            value: 'actions',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.userQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
            })
         }
      }
    },

    created () {
      let params = Object.assign({}, this.queryParams.params)
      params.page--
      this.userQuery({
        params: params,
        data: {}
      }).then( (res) => {
        this.queryParams.params.pageCount = res.totalPages
      })
    },

    methods: {
      ...mapActions({
        userQuery: 'user/query',
        userDelete: 'user/delete',
        userSetMonitorScreenUser: 'user/setMonitorScreenUser',
        userUnSetMonitorScreenUser: 'user/unSetMonitorScreenUser',
        setEpidemicMonitorUser: 'user/setEpidemicMonitorUser',
        unSetEpidemicMonitorUser: 'user/unSetEpidemicMonitorUser'
      }),

      handleSearchFormSubmit(data) {
        this.queryParams.data = data
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.userQuery({params: params, data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      },

      handleEditItem (item) {
        this.$router.push({name: 'systemUserEdit', params: { id: item.id + '' } })
      },

      handleDeleteItem (item) {
        this.editItem = item
        this.deleteDialog = true
      },

      handleDeleteDialog () {
        this.userDelete(this.editItem).then( () => {
          this.deleteDialog = false
        })
      },

      handleMonitorScreenItem (item) {
        this.editItem = item
        this.monitorScreenDialog = true
      },

      handleEpidemicMonitorItem (item) {
        this.editItem = item
        this.epidemicMonitorDialog = true
      },

      handleAddMonitorScreenDialog () {
        this.userSetMonitorScreenUser(this.editItem).then( () => {
          this.monitorScreenDialog = false
        })
      },

      handleDeleteMonitorScreenDialog () {
        this.userUnSetMonitorScreenUser(this.editItem).then( () => {
          this.monitorScreenDialog = false
        })
      },

      handleAddEpidemicMonitorDialog () {
        this.setEpidemicMonitorUser(this.editItem).then( () => {
          this.epidemicMonitorDialog = false
        })
      },

      handleDeleteEpidemicMonitorDialog () {
        this.unSetEpidemicMonitorUser(this.editItem).then( () => {
          this.epidemicMonitorDialog = false
        })
      }
    },
  }
</script>
